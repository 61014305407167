<template>
  <section class="setting-institutions-router" v-auth="'create,update,remove'">
    <div class="header">
      <div class="title">下拉字段列表</div>
      <el-button type="primary" size="small" @click="edit('create')" v-if="auth.create">添加字段</el-button>
    </div>
    <div class="form">
      <el-table :data="table.data" row-key="id">
        >
        <el-table-column prop="index" label="序号" type="index"></el-table-column>
        <el-table-column prop="description" label="名称"></el-table-column>
        <el-table-column prop="name" label="标识"></el-table-column>
        <el-table-column prop="value" label="值"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="prop" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit('create',scope.row)" type="text" size="small" v-if=" auth.create">添加子字段</el-button>
            <el-button @click="edit('update',scope.row)" type="text" size="small" v-if="auth.update">修改</el-button>
            <el-button @click="edit('remove',scope.row)" type="text" size="small" v-if="auth.remove">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <edit :table="table.data" :title="edit_title" ref="edit" @getList="getList" v-if="show" @changeShow="changeShow"></edit>
  </section>
</template>

<script>
import edit from './edit.vue';
import Dictionary from '@/api/dictionary';
export default {
  // 机构管理
  name: '',
  components: { edit },
  data() {
    return {
      table: {
        data: [],
        loading: false,
      },
      edit_title: '添加字段',
      show: false,
      auth: {
        create: true,
        update: true,
        remove: true,
      },
    };
  },
  methods: {
    changeShow(show) {
      this.show = show;
    },
    edit(type, data) {
      this.show = true;
      this.changeShow(true);
      this.$nextTick(() => {
        switch (type) {
          case 'create': {
            this.edit_title = '添加字段';
            if (data) {
              this.$refs.edit.toggle(type, true, data);
            } else {
              this.$refs.edit.toggle(type, true);
            }
            break;
          }
          case 'update': {
            this.edit_title = '修改字段';
            this.$refs.edit.toggle(type, true, data);
            break;
          }
          case 'remove': {
            this.$confirm(`确定删除 --${data.description}  字段？`, '提示', {
              type: 'warning',
              showCancelButton: true,
            }).then(() => {
              Dictionary.remove(data.id).then(() => {
                this.$message('删除成功', 'success');
                this.getList();
              });
            });
            break;
          }
        }
      });
    },
    getList() {
      this.table.loading = true;
      Dictionary.list()
        .then((res) => {
          this.table.data = res;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {
    return this.getList();
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.setting-institutions-router {
  background-color: #fff;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    .title {
      color: $h4;
      font-weight: bold;
    }
    .el-button {
      display: flex;
      // justify-content: flex-start;
    }
  }
  .form {
    flex: 1;
    width: calc(100% - 30px);
    // width: 100%;
  }
}
</style>
